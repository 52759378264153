/*
  Resume Website project
  Author: Dan J. Hamilton
  Date:   15 February 2016

  Filename:         index.html
  Supporting files: modernizr-1.5.js, styles_main.css
*/
import React from "react";
import { Link } from "gatsby";

import SEO from "../../components/seo";
import Image from "../../components/image"
import "../../styles/resume_styles.css";

export default () => (
  <>
    <SEO title="Resume of Dan J. Hamilton" />

    <div className="all-content">
      <div className="nav">
        <ul>
          <li><Link to="/index-project/" title="Project Index">Project Index</Link></li>
          <li><Link to="/resume/" title="Welcome Page">Welcome Page</Link></li>
          <li><Link to="/resume/employmenthistory/" title="Resume">Resume</Link></li>
          <li><Link to="/resume/bio/" title="Biography">Biography</Link></li>
          <li><Link to="/resume/music/" title="Favorite Music">Favorite Music</Link></li>
          <li><Link to="/resume/food/" title="Favorite Food">Favorite Food</Link></li>
          <li><Link to="/resume/about/" title="About this Website">About this Website</Link></li>
        </ul>
      </div>

      <section className="resume-main-content">
        <div className="section-header">
          <h1>Favorite Music</h1>
        </div>

        <Image className="img-float-right" value="styx" alt="Styx Logo" />
		
        <p id="firstp">   
          Styx
        </p>

        <p>
			    Style of Music:  Rock
        </p>

        <div>
          <strong>Studio Albums:</strong>
          <ul>
            <li><em>Styx</em></li>
            <li><em>Styx II</em></li>
            <li><em>The Serpent is Rising</em></li>
            <li><em>Man of Miracles</em></li>
            <li><em>Equinox</em></li>
            <li><em>Crystal Ball</em></li>
            <li><em>The Grand Illusion</em></li>
            <li><em>Pieces of Eight</em></li>
            <li><em>Cornerstone</em></li>
            <li><em>Paradise Theatre</em></li>
            <li><em>Kilroy Was Here</em></li>
            <li><em>Edge of the Century</em></li>
            <li><em>Brave New World</em></li>
            <li><em>Cyclorama</em></li>
            <li><em>Big Bang Theory</em></li>
          </ul>
         </div>
      </section>

      <div className="footer">
         <div className="address">
            Winter Garden, FL &nbsp;34787
         </div>
      </div>
    </div>
  </>
)